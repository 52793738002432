import Vue from 'vue'
import store from '@/store';
import vuetify from '@/plugins/vuetify'
import MapActions from './MapActions.vue'

const MapActionsController = class {
  constructor(propsData) {
    Object.setPrototypeOf(this, window.ol.control.Control.prototype)
    const Constructor = Vue.extend(MapActions)
    this.component = new Constructor({
      store,
      vuetify,
      propsData
    }).$mount()

    window.ol.control.Control.call(this, {
      element: this.component.$el
    });
  }
}

// MapActionsController.prototype = Object.create(
//   MapActionsController.prototype && window.ol.control.Control && window.ol.control.Control.prototype
// )

// // eslint-disable-next-line no-proto
// MapActionsController.__proto__ = window.ol.control.Control

export default MapActionsController
