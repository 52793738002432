<template>
  <my-window
    :title="tittle"
    :width="width"
    :height="height"
    :top="top"
    :left="left"
    :camera="false"
    :actions="actions"
    :onClose="onClose"
    ref="iframeWindow"
  >
    <div class="camdiv">
      <embed
        class="camdiv"
        :title="tittle"
        :src="url"
      />
    </div>
  </my-window>
</template>
<script>
import Window from '@/components/Window.vue';
import ApiService from '@/util/api.service';

export default {
  components: {
    'my-window': Window
  },
  props: {
    url: String,
    type: String,
    top: Number,
    left: Number,
    onClose: Function,
    actions: Array,
    tittle: {
      type: String,
      default: 'hola'
    },
    width: {
      type: Number,
      default: 480
    },
    height: {
      type: Number,
      default: 320
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
  .camdiv {
    vertical-align: middle;
    height: 100%;
    width: 100%;
    border: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .colaso {
    text-align: center;
    padding: 5px;
    border: 1px solid white;
  }
  .titlebar {
    padding: 0em 0.5em !important;
  }
</style>
