import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import CameraWindow from './CameraWindow.vue'

const CameraWindowController = class {
  constructor(props) {
    const Constructor = Vue.extend(CameraWindow)
    this.component = new Constructor({
      vuetify,
      propsData: props
    }).$mount()
  }

  get() {
    return this.component.$el
  }
}

export default CameraWindowController
