<template>
  <div class="hidebutton" v-if="!show">
    <v-tooltip left>
      <template v-slot:activator='{ on }'>
        <v-btn
          style="color: rgb(255 255 255 / 70%) !important; background-color: rgb(20,20,20,.70); pointer-events: auto;"
          icon x-large @click="toggle" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span>INFORMACIÓN</span>
    </v-tooltip>
  </div>
  <map-card title="Información" :actions="getActions" v-else>
    <template v-slot:body>
      <div v-if="getSelectedItem != undefined" style="height: 100%;">
        <v-simple-table dense class="desc-table" dark>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, key) in getSelectedItem.data" :key="key" >
                <td v-if="item.label !== 'FOTO'"><b>{{ item.label }}</b></td>
                <td v-if="item.label === 'FOTO'" v-html="item.value" style="text-align: center; padding-top: 5px;" colspan="2"></td>
                <td  v-if="item.label !== 'FOTO'" v-html="item.value"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </template>
  </map-card>
</template>
<script>

export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue')
  },
  data() {
    return {
      show: true,
      actions: [{
        icon: 'mdi-content-copy',
        help: 'Copiar',
        condition: () => this.getSelectedItem,
        action: () => {
          const text = this.getSelectedItem.data.map((item) => {
            if (item.label === 'FOTO') {
              return '';
            }
            return `${item.label}: ${item.value}`;
          }).join('\n');
          window.navigator.clipboard.writeText(text);
        }
      }, {
        icon: 'mdi-triangle-outline mdi-rotate-90',
        help: 'Ocultar',
        action: this.toggle
      }]
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    getSelectedItem() {
      // eslint-disable-next-line
      this.show = true
      return this.$store.getters['map/getSelectedItem']
    },
    getActions() {
      return [
        ...(this.getSelectedItem?.actions?.filter(action => {
          return action.condition ? action.condition(this.getSelectedItem) : true
        }) || []),
        ...this.actions.filter(action => {
          return action.condition ? action.condition(this.getSelectedItem) : true
        }),
      ]
      // (this.getSelectedItem && this.getSelectedItem.actions ? this.getSelectedItem.actions : []).concat(this.actions)
    }
  },
  watch: {
    getSelectedItem(val) {
      if (val) {
        this.show = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-card-alert {
  border-bottom: 1px solid;
}

.hidebutton {
  width: 100%;
  text-align: right;
  padding: 4px, 16px;
}

.desc-card-header {
  width: 100%;
  height: 30px;
  background-color: black;
}

.desc-title-card-header {
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
}

.no-selected-item {
  font-size: 36px;
  height: 350px;
  width: 100%;
}

.desc-table {
  height: 100%;
  overflow: auto;
}

/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow:
    0 3px 1px -2px rgba (0, 0, 0, .2),
    0 2px 2px 0 rgba (0, 0, 0, .14),
    0 1px 5px 0 rgba (0, 0, 0, .12) !important;
}
</style>
