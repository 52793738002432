import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import IframeWindow from './IframeWindow.vue'

const IframeWindowController = class {
  constructor(props) {
    const Constructor = Vue.extend(IframeWindow)
    this.component = new Constructor({
      vuetify,
      propsData: props
    }).$mount()
  }

  get() {
    return this.component.$el
  }
}

export default IframeWindowController
