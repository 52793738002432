var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"windowElement",staticClass:"window",style:({
    display: 'initial',
    width: _vm.width + 'px',
    height: _vm.height + 40 + 'px',
    left: _vm.left + 'px',
    top: _vm.top + 'px',
    bottom: _vm.bottom
  })},[_c('div',{ref:"windowHeader",staticClass:"window-header"},[_c('p',{staticClass:"windowTitle"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{staticClass:"close-button"},[_vm._l((_vm.actions),function(action,key){return _c('v-btn',{key:key,attrs:{"icon":"","dark":"","small":""},on:{"click":action.action}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)}),_c('v-tooltip',{attrs:{"bottom":""}},[(!_vm.fullScreen)?_c('v-btn',{attrs:{"icon":"","dark":"","small":""},on:{"click":_vm.setFullScreen}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-fullscreen ")])],1):_c('v-btn',{attrs:{"icon":"","dark":"","small":""},on:{"click":_vm.setBackScreen}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-fullscreen-exit ")])],1),(!_vm.fullScreen)?_c('span',[_vm._v("PANTALLA COMPLETA")]):_c('span',[_vm._v("SALIR DE PANTALLA COMPLETA")])],1),_c('v-btn',{attrs:{"icon":"","dark":"","small":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],2)]),_c('div',{staticClass:"mainWindow",style:({
      height: 'calc(100% - 40px)'
    })},[_vm._t("default")],2),_vm._l((_vm.resizers),function(resizer){return _c('div',{key:resizer,ref:resizer,refInFor:true,class:'resizer ' + resizer})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }