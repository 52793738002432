<template>
  <div class="clock" v-html="time"></div>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      time: null
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
      this.time = this.time.replace(',', '<br>')
    }, 1000)
  }
}
</script>

<style scoped>
.clock {
  color: #ffffff;
  align-self: center;
  font-family: 'Roboto';
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  padding-right: 10px;
}
</style>
