export default {
  'Accidente acuático con heridos': {
    id: 10101,
    alertType: 'Accidente acuático con heridos',
    prioridad: 'Urgente'
  },
  'Accidente acuático sin heridos': {
    id: 10101,
    alertType: 'Accidente acuático sin heridos',
    prioridad: 'Urgente'
  },
  'Accidente aéreo con heridos': {
    id: 10102,
    alertType: 'Accidente aéreo con heridos',
    prioridad: 'Urgente'
  },
  'Accidente aéreo sin heridos': {
    id: 10102,
    alertType: 'Accidente aéreo sin heridos',
    prioridad: 'Urgente'
  },
  'Apoyo / Aterrizaje forzoso de vehículo aéreo': {
    id: 10102,
    alertType: 'Apoyo / Aterrizaje forzoso de vehículo aéreo',
    prioridad: 'Urgente'
  },
  'Accidente / Choque de vehículo motocicleta con heridos': {
    id: 10103,
    alertType: 'Accidente / Choque de vehículo motocicleta con heridos',
    prioridad: 'Urgente'
  },
  'Accidente / Derrapamiento con heridos': {
    id: 10103,
    alertType: 'Accidente / Derrapamiento con heridos',
    prioridad: 'Rápida'
  },
  'Accidente / Choque de vehículo con heridos': {
    id: 10104,
    alertType: 'Accidente / Choque de vehículo con heridos',
    prioridad: 'Urgente'
  },
  'Accidente / Salida de camino con heridos': {
    id: 10104,
    alertType: 'Accidente / Salida de camino con heridos',
    prioridad: 'Rápida'
  },
  'Accidente / Volcadura con heridos': {
    id: 10104,
    alertType: 'Accidente / Volcadura con heridos',
    prioridad: 'Urgente'
  },
  'Accidente ferroviario con heridos': {
    id: 10105,
    alertType: 'Accidente ferroviario con heridos',
    prioridad: 'Urgente'
  },
  'Accidente ferroviario sin heridos': {
    id: 10105,
    alertType: 'Accidente ferroviario sin heridos',
    prioridad: 'Urgente'
  },
  Atropellamiento: {
    id: 10116,
    alertType: 'Atropellamiento',
    prioridad: 'Urgente'
  },
  'Persona asfixiándose': {
    id: 10203,
    alertType: 'Persona asfixiándose',
    prioridad: 'Rápida'
  },
  'Persona lesionada accidental por caída': {
    id: 10204,
    alertType: 'Persona lesionada accidental por caída',
    prioridad: 'Urgente'
  },
  'Persona lesionada por caída de vehículo': {
    id: 10204,
    alertType: 'Persona lesionada por caída de vehículo',
    prioridad: 'Rápida'
  },
  'Persona lesionada accidental por descarga eléctrica': {
    id: 10205,
    alertType: 'Persona lesionada accidental por descarga eléctrica',
    prioridad: 'Urgente'
  },
  'Persona lesionada accidental por herida cortante con objeto punzo cortante': {
    id: 10208,
    alertType: 'Persona lesionada accidental por herida cortante con objeto punzo cortante',
    prioridad: 'Urgente'
  },
  'Persona lesionada por arma punzo cortante': {
    id: 10208,
    alertType: 'Persona lesionada por arma punzo cortante',
    prioridad: 'Urgente'
  },
  'Persona lesionada accidental con arma de fuego': {
    id: 10209,
    alertType: 'Persona lesionada accidental con arma de fuego',
    prioridad: 'Urgente'
  },
  'Persona lesionada por arma de fuego': {
    id: 10209,
    alertType: 'Persona lesionada por arma de fuego',
    prioridad: 'Urgente'
  },
  'Persona lesionada por animal mordedura': {
    id: 10210,
    alertType: 'Persona lesionada por animal mordedura',
    prioridad: 'Rápida'
  },
  'Persona lesionada accidental por quemadura': {
    id: 10211,
    alertType: 'Persona lesionada accidental por quemadura',
    prioridad: 'Urgente'
  },
  'Persona lesionada accidental por quemadura con cohetes / Fuegos artificiales': {
    id: 10211,
    alertType: 'Persona lesionada accidental por quemadura con cohetes / Fuegos artificiales',
    prioridad: 'Urgente'
  },
  'Persona muerta / Se ignora causa': {
    id: 10219,
    alertType: 'Persona muerta / Se ignora causa',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental con arma de fuego': {
    id: 10219,
    alertType: 'Persona muerta accidental con arma de fuego',
    prioridad: 'Urgente'
  },
  'Persona muerta accidental por  golpe con maquinaria': {
    id: 10219,
    alertType: 'Persona muerta accidental por  golpe con maquinaria',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por asfixia': {
    id: 10219,
    alertType: 'Persona muerta accidental por asfixia',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por atrapamiento de parte corporal': {
    id: 10219,
    alertType: 'Persona muerta accidental por atrapamiento de parte corporal',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por caída': {
    id: 10219,
    alertType: 'Persona muerta accidental por caída',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por descarga eléctrica': {
    id: 10219,
    alertType: 'Persona muerta accidental por descarga eléctrica',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por golpe con objeto contundente': {
    id: 10219,
    alertType: 'Persona muerta accidental por golpe con objeto contundente',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por golpe por caerle objeto encima': {
    id: 10219,
    alertType: 'Persona muerta accidental por golpe por caerle objeto encima',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por herida contante con equipo / Maquinaria': {
    id: 10219,
    alertType: 'Persona muerta accidental por herida contante con equipo / Maquinaria',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por herida cortante con objeto punzo cortante': {
    id: 10219,
    alertType: 'Persona muerta accidental por herida cortante con objeto punzo cortante',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por quemadura': {
    id: 10219,
    alertType: 'Persona muerta accidental por quemadura',
    prioridad: 'Rápida'
  },
  'Persona muerta accidental por quemadura con cohetes / Fuegos artificiales': {
    id: 10219,
    alertType: 'Persona muerta accidental por quemadura con cohetes / Fuegos artificiales',
    prioridad: 'Rápida'
  },
  'Persona muerta por animal / Mordedura': {
    id: 10219,
    alertType: 'Persona muerta por animal / Mordedura',
    prioridad: 'Rápida'
  },
  'Persona muerta por animal / Patada de animal': {
    id: 10219,
    alertType: 'Persona muerta por animal / Patada de animal',
    prioridad: 'Rápida'
  },
  'Persona muerta por animal picadura de abeja': {
    id: 10219,
    alertType: 'Persona muerta por animal picadura de abeja',
    prioridad: 'Rápida'
  },
  'Persona muerta por animal picadura de alacrán': {
    id: 10219,
    alertType: 'Persona muerta por animal picadura de alacrán',
    prioridad: 'Rápida'
  },
  'Persona muerta por animal picadura de mantarraya': {
    id: 10219,
    alertType: 'Persona muerta por animal picadura de mantarraya',
    prioridad: 'Rápida'
  },
  'Persona muerta por arma de fuego': {
    id: 10219,
    alertType: 'Persona muerta por arma de fuego',
    prioridad: 'Urgente'
  },
  'Persona muerta por arma punzo cortante': {
    id: 10219,
    alertType: 'Persona muerta por arma punzo cortante',
    prioridad: 'Urgente'
  },
  'Persona muerta por asfixia': {
    id: 10219,
    alertType: 'Persona muerta por asfixia',
    prioridad: 'Rápida'
  },
  'Persona muerta por ataque cardiaco': {
    id: 10219,
    alertType: 'Persona muerta por ataque cardiaco',
    prioridad: 'Rápida'
  },
  'Persona muerta por ataque epiléptico': {
    id: 10219,
    alertType: 'Persona muerta por ataque epiléptico',
    prioridad: 'Rápida'
  },
  'Persona muerta por deshidratación': {
    id: 10219,
    alertType: 'Persona muerta por deshidratación',
    prioridad: 'Rápida'
  },
  'Persona muerta por enfermedad': {
    id: 10219,
    alertType: 'Persona muerta por enfermedad',
    prioridad: 'Rápida'
  },
  'Persona muerta por golpes': {
    id: 10219,
    alertType: 'Persona muerta por golpes',
    prioridad: 'Urgente'
  },
  'Persona muerta por inmersión en medio líquido': {
    id: 10219,
    alertType: 'Persona muerta por inmersión en medio líquido',
    prioridad: 'Urgente'
  },
  'Persona muerta por intoxicación alcohólica': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación alcohólica',
    prioridad: 'Rápida'
  },
  'Persona muerta por intoxicación alimenticia': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación alimenticia',
    prioridad: 'Rápida'
  },
  'Persona muerta por intoxicación por consumo de drogas enervantes': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación por consumo de drogas enervantes',
    prioridad: 'Rápida'
  },
  'Persona muerta por intoxicación por consumo de medicamentos': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación por consumo de medicamentos',
    prioridad: 'Rápida'
  },
  'Persona muerta por intoxicación por ingerir sustancias tóxicas': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación por ingerir sustancias tóxicas',
    prioridad: 'Rápida'
  },
  'Persona muerta por intoxicación por inhalar sustancias tóxicas': {
    id: 10219,
    alertType: 'Persona muerta por intoxicación por inhalar sustancias tóxicas',
    prioridad: 'Rápida'
  },
  'Persona lesionada accidental por atrapamiento de parte corporal': {
    id: 10220,
    alertType: 'Persona lesionada accidental por atrapamiento de parte corporal',
    prioridad: 'Urgente'
  },
  'Persona lesionada accidental por golpes': {
    id: 10220,
    alertType: 'Persona lesionada accidental por golpes',
    prioridad: 'Rápida'
  },
  'Persona lesionada accidental por herida cortante con equipo / Maquinaria': {
    id: 10220,
    alertType: 'Persona lesionada accidental por herida cortante con equipo / Maquinaria',
    prioridad: 'Urgente'
  },
  'Persona lesionada por animal patada de animal': {
    id: 10220,
    alertType: 'Persona lesionada por animal patada de animal',
    prioridad: 'Rápida'
  },
  'Persona lesionada por animal picadura de abeja': {
    id: 10220,
    alertType: 'Persona lesionada por animal picadura de abeja',
    prioridad: 'Urgente'
  },
  'Persona lesionada por animal picadura de alacrán': {
    id: 10220,
    alertType: 'Persona lesionada por animal picadura de alacrán',
    prioridad: 'Rápida'
  },
  'Persona lesionada por animal picadura de mantarraya': {
    id: 10220,
    alertType: 'Persona lesionada por animal picadura de mantarraya',
    prioridad: 'Rápida'
  },
  'Persona lesionada por animal picadura erizo': {
    id: 10220,
    alertType: 'Persona lesionada por animal picadura erizo',
    prioridad: 'Rápida'
  },
  'Persona lesionada por animal quemador': {
    id: 10220,
    alertType: 'Persona lesionada por animal quemador',
    prioridad: 'Rápida'
  },
  'Persona lesionada por golpes': {
    id: 10220,
    alertType: 'Persona lesionada por golpes',
    prioridad: 'Urgente'
  },
  'Apoyo / Parto en proceso': {
    id: 10301,
    alertType: 'Apoyo / Parto en proceso',
    prioridad: 'Urgente'
  },
  Aborto: {
    id: 10302,
    alertType: 'Aborto',
    prioridad: 'Urgente'
  },
  'Apoyo / Por amenaza de aborto': {
    id: 10302,
    alertType: 'Apoyo / Por amenaza de aborto',
    prioridad: 'Urgente'
  },
  'Persona intoxicada por alcohol': {
    id: 10306,
    alertType: 'Persona intoxicada por alcohol',
    prioridad: 'Rápida'
  },
  'Persona inconsciente': {
    id: 10308,
    alertType: 'Persona inconsciente',
    prioridad: 'Urgente'
  },
  'Persona tirada en la vía pública': {
    id: 10308,
    alertType: 'Persona tirada en la vía pública',
    prioridad: 'Rápida'
  },
  'Persona enferma': {
    id: 10310,
    alertType: 'Persona enferma',
    prioridad: 'Rápida'
  },
  'Persona intoxicada por alimentos': {
    id: 10310,
    alertType: 'Persona intoxicada por alimentos',
    prioridad: 'Rápida'
  },
  'Persona intoxicada por consumir drogas enervantes': {
    id: 10315,
    alertType: 'Persona intoxicada por consumir drogas enervantes',
    prioridad: 'Rápida'
  },
  'Persona intoxicada por consumo de medicamentos': {
    id: 10315,
    alertType: 'Persona intoxicada por consumo de medicamentos',
    prioridad: 'Rápida'
  },
  'Persona intoxicada por ingerir sustancias tóxicas': {
    id: 10315,
    alertType: 'Persona intoxicada por ingerir sustancias tóxicas',
    prioridad: 'Urgente'
  },
  'Persona intoxicada por inhalar sustancias tóxicas': {
    id: 10315,
    alertType: 'Persona intoxicada por inhalar sustancias tóxicas',
    prioridad: 'Urgente'
  },
  'Apoyo / Revisiones médicas': {
    id: 10316,
    alertType: 'Apoyo / Revisiones médicas',
    prioridad: 'Rápida'
  },
  'Persona muerta naturalmente': {
    id: 10317,
    alertType: 'Persona muerta naturalmente',
    prioridad: 'Rápida'
  },
  'Persona en crisis nerviosa': {
    id: 10318,
    alertType: 'Persona en crisis nerviosa',
    prioridad: 'Normal'
  },
  'Persona enferma mentalmente': {
    id: 10318,
    alertType: 'Persona enferma mentalmente',
    prioridad: 'Rápida'
  },
  'Localización de combustible': {
    id: 20102,
    alertType: 'Localización de combustible',
    prioridad: 'Rápida'
  },
  'Localización de droga laboratorio clandestino': {
    id: 20102,
    alertType: 'Localización de droga laboratorio clandestino',
    prioridad: 'Rápida'
  },
  'Localización de droga plantío': {
    id: 20102,
    alertType: 'Localización de droga plantío',
    prioridad: 'Rápida'
  },
  'Localización de droga porciones / paquetes': {
    id: 20102,
    alertType: 'Localización de droga porciones / paquetes',
    prioridad: 'Rápida'
  },
  'Control de animal peligroso / Perro': {
    id: 20103,
    alertType: 'Control de animal peligroso / Perro',
    prioridad: 'Rápida'
  },
  'Control de animal peligroso / Víbora': {
    id: 20103,
    alertType: 'Control de animal peligroso / Víbora',
    prioridad: 'Rápida'
  },
  'Desbordamiento de arroyo': {
    id: 20106,
    alertType: 'Desbordamiento de arroyo',
    prioridad: 'Urgente'
  },
  'Desbordamiento de canal': {
    id: 20106,
    alertType: 'Desbordamiento de canal',
    prioridad: 'Rápida'
  },
  'Desbordamiento de dique / Presa': {
    id: 20106,
    alertType: 'Desbordamiento de dique / Presa',
    prioridad: 'Urgente'
  },
  'Desbordamiento de río': {
    id: 20106,
    alertType: 'Desbordamiento de río',
    prioridad: 'Rápida'
  },
  'Explosión de alcantarilla': {
    id: 20107,
    alertType: 'Explosión de alcantarilla',
    prioridad: 'Urgente'
  },
  'Explosión de artefacto explosivo': {
    id: 20107,
    alertType: 'Explosión de artefacto explosivo',
    prioridad: 'Urgente'
  },
  'Explosión de bomba de abastecimiento': {
    id: 20107,
    alertType: 'Explosión de bomba de abastecimiento',
    prioridad: 'Urgente'
  },
  'Explosión de línea de conducción de sustancia gaseosa': {
    id: 20107,
    alertType: 'Explosión de línea de conducción de sustancia gaseosa',
    prioridad: 'Urgente'
  },
  'Explosión de línea de conducción de sustancia líquida': {
    id: 20107,
    alertType: 'Explosión de línea de conducción de sustancia líquida',
    prioridad: 'Urgente'
  },
  'Explosión de tanque de almacenamiento': {
    id: 20107,
    alertType: 'Explosión de tanque de almacenamiento',
    prioridad: 'Urgente'
  },
  'Explosión de vehículo': {
    id: 20107,
    alertType: 'Explosión de vehículo',
    prioridad: 'Urgente'
  },
  'Fuga de sustancia tóxica gaseosa': {
    id: 20108,
    alertType: 'Fuga de sustancia tóxica gaseosa',
    prioridad: 'Urgente'
  },
  'Fuga de sustancia tóxica líquida': {
    id: 20108,
    alertType: 'Fuga de sustancia tóxica líquida',
    prioridad: 'Urgente'
  },
  'Servicio público / Malos olores': {
    id: 20111,
    alertType: 'Servicio público / Malos olores',
    prioridad: 'Normal'
  },
  'Servicio público / Objeto fijo caído': {
    id: 20113,
    alertType: 'Servicio público / Objeto fijo caído',
    prioridad: 'Normal'
  },
  'Faltas / Contaminación de suelo\t aire y agua': {
    id: 20201,
    alertType: 'Faltas / Contaminación de suelo\t aire y agua',
    prioridad: 'Normal'
  },
  'Derrumbe de cerro': {
    id: 20202,
    alertType: 'Derrumbe de cerro',
    prioridad: 'Urgente'
  },
  'Derrumbe de edificación': {
    id: 20202,
    alertType: 'Derrumbe de edificación',
    prioridad: 'Urgente'
  },
  'Derrumbe de excavación': {
    id: 20202,
    alertType: 'Derrumbe de excavación',
    prioridad: 'Urgente'
  },
  'Derrumbe de mina': {
    id: 20202,
    alertType: 'Derrumbe de mina',
    prioridad: 'Urgente'
  },
  'Control de animal peligroso / Panel / Enjambre': {
    id: 20203,
    alertType: 'Control de animal peligroso / Panel / Enjambre',
    prioridad: 'Rápida'
  },
  Helada: {
    id: 20205,
    alertType: 'Helada',
    prioridad: 'Urgente'
  },
  'Inundación en área rural': {
    id: 20207,
    alertType: 'Inundación en área rural',
    prioridad: 'Rápida'
  },
  'Inundación en área urbana': {
    id: 20207,
    alertType: 'Inundación en área urbana',
    prioridad: 'Urgente'
  },
  Sismo: {
    id: 20209,
    alertType: 'Sismo',
    prioridad: 'Urgente'
  },
  'Incendio a casa habitación': {
    id: 20301,
    alertType: 'Incendio a casa habitación',
    prioridad: 'Urgente'
  },
  'Incendio de vehículo': {
    id: 20303,
    alertType: 'Incendio de vehículo',
    prioridad: 'Urgente'
  },
  'Incendio de vehículo vagón de ferrocarril': {
    id: 20303,
    alertType: 'Incendio de vehículo vagón de ferrocarril',
    prioridad: 'Urgente'
  },
  'Incendio a comercio': {
    id: 20304,
    alertType: 'Incendio a comercio',
    prioridad: 'Urgente'
  },
  'Incendio de bomba de abastecimiento': {
    id: 20304,
    alertType: 'Incendio de bomba de abastecimiento',
    prioridad: 'Urgente'
  },
  'Incendio de edificación': {
    id: 20305,
    alertType: 'Incendio de edificación',
    prioridad: 'Urgente'
  },
  'Incendio forestal': {
    id: 20307,
    alertType: 'Incendio forestal',
    prioridad: 'Rápida'
  },
  'Incendio de baldío / Predio': {
    id: 20308,
    alertType: 'Incendio de baldío / Predio',
    prioridad: 'Rápida'
  },
  'Incendio de forrajes': {
    id: 20309,
    alertType: 'Incendio de forrajes',
    prioridad: 'Rápida'
  },
  'Incendio de sembradío / Parcela': {
    id: 20309,
    alertType: 'Incendio de sembradío / Parcela',
    prioridad: 'Rápida'
  },
  'Incendio de línea de conducción de sustancia gaseosa': {
    id: 20310,
    alertType: 'Incendio de línea de conducción de sustancia gaseosa',
    prioridad: 'Urgente'
  },
  'Incendio de línea de conducción de sustancia líquida': {
    id: 20310,
    alertType: 'Incendio de línea de conducción de sustancia líquida',
    prioridad: 'Urgente'
  },
  'Incendio de tanque de almacenamiento': {
    id: 20310,
    alertType: 'Incendio de tanque de almacenamiento',
    prioridad: 'Urgente'
  },
  'Salvamento acuático de persona en peligro de muerte': {
    id: 20401,
    alertType: 'Salvamento acuático de persona en peligro de muerte',
    prioridad: 'Urgente'
  },
  'Apoyo / animal varado': {
    id: 20403,
    alertType: 'Apoyo / animal varado',
    prioridad: 'Normal'
  },
  'Vehículo Abandonado': {
    id: 30101,
    alertType: 'Vehículo Abandonado',
    prioridad: 'Normal'
  },
  'Localización de artefacto explosivo': {
    id: 30102,
    alertType: 'Localización de artefacto explosivo',
    prioridad: 'Urgente'
  },
  'Localización de cascajos de arma de fuego': {
    id: 30102,
    alertType: 'Localización de cascajos de arma de fuego',
    prioridad: 'Urgente'
  },
  'Localización de municiones de arma de fuego': {
    id: 30102,
    alertType: 'Localización de municiones de arma de fuego',
    prioridad: 'Urgente'
  },
  'Disparo de arma': {
    id: 30202,
    alertType: 'Disparo de arma',
    prioridad: 'Urgente'
  },
  'Portación de arma': {
    id: 30204,
    alertType: 'Portación de arma',
    prioridad: 'Urgente'
  },
  'Portación de artefacto explosivo': {
    id: 30204,
    alertType: 'Portación de artefacto explosivo',
    prioridad: 'Urgente'
  },
  'Portación de cartuchos de arma de fuego': {
    id: 30204,
    alertType: 'Portación de cartuchos de arma de fuego',
    prioridad: 'Urgente'
  },
  'Posesión de arma': {
    id: 30204,
    alertType: 'Posesión de arma',
    prioridad: 'Urgente'
  },
  'Faltas / Encender fogatas y cohetes': {
    id: 30205,
    alertType: 'Faltas / Encender fogatas y cohetes',
    prioridad: 'Normal'
  },
  'Faltas/ Detonación de cohetes': {
    id: 30205,
    alertType: 'Faltas/ Detonación de cohetes',
    prioridad: 'Normal'
  },
  'Tráfico ilegal de armas': {
    id: 30207,
    alertType: 'Tráfico ilegal de armas',
    prioridad: 'Urgente'
  },
  'Venta ilegal de armas': {
    id: 30207,
    alertType: 'Venta ilegal de armas',
    prioridad: 'Urgente'
  },
  'Apuesta ilegal / En arrancones de vehículos': {
    id: 30302,
    alertType: 'Apuesta ilegal / En arrancones de vehículos',
    prioridad: 'Rápida'
  },
  'Faltas / Vehículo a exceso de velocidad': {
    id: 30305,
    alertType: 'Faltas / Vehículo a exceso de velocidad',
    prioridad: 'Normal'
  },
  'Apoyo / Persecución policial': {
    id: 30306,
    alertType: 'Apoyo / Persecución policial',
    prioridad: 'Rápida'
  },
  'Vehículo sospechoso': {
    id: 30307,
    alertType: 'Vehículo sospechoso',
    prioridad: 'Rápida'
  },
  'Accidente / Choque de vehículo motocicleta sin heridos': {
    id: 30309,
    alertType: 'Accidente / Choque de vehículo motocicleta sin heridos',
    prioridad: 'Urgente'
  },
  'Accidente / Choque de vehículo sin heridos': {
    id: 30309,
    alertType: 'Accidente / Choque de vehículo sin heridos',
    prioridad: 'Urgente'
  },
  'Accidente / Derrapamiento sin heridos': {
    id: 30309,
    alertType: 'Accidente / Derrapamiento sin heridos',
    prioridad: 'Rápida'
  },
  'Accidente / Salida de camino sin heridos': {
    id: 30309,
    alertType: 'Accidente / Salida de camino sin heridos',
    prioridad: 'Rápida'
  },
  'Accidente / Volcadura sin heridos': {
    id: 30309,
    alertType: 'Accidente / Volcadura sin heridos',
    prioridad: 'Rápida'
  },
  'Faltas / Estacionarse en lugar prohibido': {
    id: 30310,
    alertType: 'Faltas / Estacionarse en lugar prohibido',
    prioridad: 'Normal'
  },
  'Vehículo obstruyendo vía de comunicación': {
    id: 30310,
    alertType: 'Vehículo obstruyendo vía de comunicación',
    prioridad: 'Rápida'
  },
  'Activación de alarma a casa habitación': {
    id: 30401,
    alertType: 'Activación de alarma a casa habitación',
    prioridad: 'Urgente'
  },
  'Activación de alarma a comercio': {
    id: 30401,
    alertType: 'Activación de alarma a comercio',
    prioridad: 'Urgente'
  },
  'Activación de alarma bancaria': {
    id: 30401,
    alertType: 'Activación de alarma bancaria',
    prioridad: 'Urgente'
  },
  'Activación de alarma en edificación': {
    id: 30401,
    alertType: 'Activación de alarma en edificación',
    prioridad: 'Urgente'
  },
  'Robo al interior del vehículo': {
    id: 30403,
    alertType: 'Robo al interior del vehículo',
    prioridad: 'Rápida'
  },
  'Daño a propiedad privada': {
    id: 30404,
    alertType: 'Daño a propiedad privada',
    prioridad: 'Rápida'
  },
  'Daño a propiedad pública': {
    id: 30404,
    alertType: 'Daño a propiedad pública',
    prioridad: 'Rápida'
  },
  Extorsión: {
    id: 30406,
    alertType: 'Extorsión',
    prioridad: 'Rápida'
  },
  'Toma clandestina de combustible': {
    id: 30409,
    alertType: 'Toma clandestina de combustible',
    prioridad: 'Rápida'
  },
  'Robo a cajero automático': {
    id: 30410,
    alertType: 'Robo a cajero automático',
    prioridad: 'Rápida'
  },
  'Persona desmantelando vehículo': {
    id: 30411,
    alertType: 'Persona desmantelando vehículo',
    prioridad: 'Rápida'
  },
  'Robo a vehículo / Accesorios': {
    id: 30411,
    alertType: 'Robo a vehículo / Accesorios',
    prioridad: 'Rápida'
  },
  'Robo de animal otro': {
    id: 30412,
    alertType: 'Robo de animal otro',
    prioridad: 'Rápida'
  },
  'Robo de ganado (Abigeato)': {
    id: 30412,
    alertType: 'Robo de ganado (Abigeato)',
    prioridad: 'Rápida'
  },
  'Robo violento a casa habitación': {
    id: 30413,
    alertType: 'Robo violento a casa habitación',
    prioridad: 'Urgente'
  },
  'Robo a casa habitación': {
    id: 30414,
    alertType: 'Robo a casa habitación',
    prioridad: 'Rápida'
  },
  'Robo violento a escuela': {
    id: 30415,
    alertType: 'Robo violento a escuela',
    prioridad: 'Urgente'
  },
  'Robo a escuela': {
    id: 30416,
    alertType: 'Robo a escuela',
    prioridad: 'Rápida'
  },
  'Robo violento a  gasolinera': {
    id: 30417,
    alertType: 'Robo violento a  gasolinera',
    prioridad: 'Urgente'
  },
  'Robo a gasolinera': {
    id: 30418,
    alertType: 'Robo a gasolinera',
    prioridad: 'Rápida'
  },
  'Robo violento a  institución privada': {
    id: 30419,
    alertType: 'Robo violento a  institución privada',
    prioridad: 'Urgente'
  },
  'Robo violento a  institución pública': {
    id: 30419,
    alertType: 'Robo violento a  institución pública',
    prioridad: 'Urgente'
  },
  'Robo violento a comercio': {
    id: 30419,
    alertType: 'Robo violento a comercio',
    prioridad: 'Urgente'
  },
  'Robo a comercio': {
    id: 30420,
    alertType: 'Robo a comercio',
    prioridad: 'Rápida'
  },
  'Robo a institución privada': {
    id: 30420,
    alertType: 'Robo a institución privada',
    prioridad: 'Rápida'
  },
  'Robo a institución pública': {
    id: 30420,
    alertType: 'Robo a institución pública',
    prioridad: 'Rápida'
  },
  'Robo violento a  persona': {
    id: 30421,
    alertType: 'Robo violento a  persona',
    prioridad: 'Urgente'
  },
  'Robo a persona': {
    id: 30422,
    alertType: 'Robo a persona',
    prioridad: 'Rápida'
  },
  'Robo a vehículo de transporte de pasajeros': {
    id: 30424,
    alertType: 'Robo a vehículo de transporte de pasajeros',
    prioridad: 'Urgente'
  },
  'Robo violento de vehículo': {
    id: 30429,
    alertType: 'Robo violento de vehículo',
    prioridad: 'Rápida'
  },
  'Robo violento de vehículo motocicleta': {
    id: 30429,
    alertType: 'Robo violento de vehículo motocicleta',
    prioridad: 'Urgente'
  },
  'Robo de vehículo': {
    id: 30430,
    alertType: 'Robo de vehículo',
    prioridad: 'Rápida'
  },
  'Robo de vehículo motocicleta': {
    id: 30430,
    alertType: 'Robo de vehículo motocicleta',
    prioridad: 'Rápida'
  },
  'Robo violento a banco': {
    id: 30433,
    alertType: 'Robo violento a banco',
    prioridad: 'Urgente'
  },
  'Robo a banco': {
    id: 30434,
    alertType: 'Robo a banco',
    prioridad: 'Rápida'
  },
  'Robo a vehículo de transporte de carga ferrocarril': {
    id: 30439,
    alertType: 'Robo a vehículo de transporte de carga ferrocarril',
    prioridad: 'Rápida'
  },
  'Robo a vehículo / Placas': {
    id: 30440,
    alertType: 'Robo a vehículo / Placas',
    prioridad: 'Rápida'
  },
  'Abuso de confianza': {
    id: 30446,
    alertType: 'Abuso de confianza',
    prioridad: 'Rápida'
  },
  Autorobo: {
    id: 30446,
    alertType: 'Autorobo',
    prioridad: 'Rápida'
  },
  'Falsificación de documentos': {
    id: 30446,
    alertType: 'Falsificación de documentos',
    prioridad: 'Normal'
  },
  Fraude: {
    id: 30446,
    alertType: 'Fraude',
    prioridad: 'Normal'
  },
  'Invasión de propiedad ajena': {
    id: 30446,
    alertType: 'Invasión de propiedad ajena',
    prioridad: 'Normal'
  },
  'Portación de placa de circulación con reporte de Robo': {
    id: 30446,
    alertType: 'Portación de placa de circulación con reporte de Robo',
    prioridad: 'Urgente'
  },
  'Portación de placa de circulación uso indebido': {
    id: 30446,
    alertType: 'Portación de placa de circulación uso indebido',
    prioridad: 'Urgente'
  },
  'Robo de cheque': {
    id: 30446,
    alertType: 'Robo de cheque',
    prioridad: 'Rápida'
  },
  'Robo en grado de tentativa': {
    id: 30446,
    alertType: 'Robo en grado de tentativa',
    prioridad: 'Rápida'
  },
  'Persona abandonada': {
    id: 30501,
    alertType: 'Persona abandonada',
    prioridad: 'Rápida'
  },
  'Violencia de pareja': {
    id: 30502,
    alertType: 'Violencia de pareja',
    prioridad: 'Rápida'
  },
  'Violencia familiar': {
    id: 30503,
    alertType: 'Violencia familiar',
    prioridad: 'Rápida'
  },
  'Persona desaparecida': {
    id: 30602,
    alertType: 'Persona desaparecida',
    prioridad: 'Rápida'
  },
  'Persona perdida': {
    id: 30602,
    alertType: 'Persona perdida',
    prioridad: 'Rápida'
  },
  'Privación ilegal de la libertad': {
    id: 30603,
    alertType: 'Privación ilegal de la libertad',
    prioridad: 'Urgente'
  },
  'Sustracción de menor': {
    id: 30607,
    alertType: 'Sustracción de menor',
    prioridad: 'Urgente'
  },
  'Persona liberada': {
    id: 30609,
    alertType: 'Persona liberada',
    prioridad: 'Rápida'
  },
  Rapto: {
    id: 30609,
    alertType: 'Rapto',
    prioridad: 'Urgente'
  },
  Secuestro: {
    id: 30609,
    alertType: 'Secuestro',
    prioridad: 'Urgente'
  },
  'Abuso sexual': {
    id: 30701,
    alertType: 'Abuso sexual',
    prioridad: 'Urgente'
  },
  'Atentado al pudor': {
    id: 30703,
    alertType: 'Atentado al pudor',
    prioridad: 'Urgente'
  },
  Violación: {
    id: 30707,
    alertType: 'Violación',
    prioridad: 'Urgente'
  },
  'Faltas / Prostitución': {
    id: 30708,
    alertType: 'Faltas / Prostitución',
    prioridad: 'Rápida'
  },
  'Persona indocumentada': {
    id: 30710,
    alertType: 'Persona indocumentada',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de indocumentados': {
    id: 30710,
    alertType: 'Tráfico ilegal de indocumentados',
    prioridad: 'Rápida'
  },
  'Corrupción de menor': {
    id: 30711,
    alertType: 'Corrupción de menor',
    prioridad: 'Urgente'
  },
  'Enfrentamiento armado entre militares y civiles': {
    id: 30803,
    alertType: 'Enfrentamiento armado entre militares y civiles',
    prioridad: 'Urgente'
  },
  'Enfrentamiento armado entre personas civiles': {
    id: 30803,
    alertType: 'Enfrentamiento armado entre personas civiles',
    prioridad: 'Urgente'
  },
  'Enfrentamiento armado entre policías y civiles': {
    id: 30803,
    alertType: 'Enfrentamiento armado entre policías y civiles',
    prioridad: 'Urgente'
  },
  'Robo de cadáver': {
    id: 30806,
    alertType: 'Robo de cadáver',
    prioridad: 'Rápida'
  },
  'Motín de reos': {
    id: 30807,
    alertType: 'Motín de reos',
    prioridad: 'Urgente'
  },
  'Almacenamiento de  cohetes / Fuegos artificiales': {
    id: 30808,
    alertType: 'Almacenamiento de  cohetes / Fuegos artificiales',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de cohetes / Fuegos artificiales': {
    id: 30808,
    alertType: 'Tráfico ilegal de cohetes / Fuegos artificiales',
    prioridad: 'Rápida'
  },
  'Venta ilegal de cohetes / Fuegos artificiales': {
    id: 30808,
    alertType: 'Venta ilegal de cohetes / Fuegos artificiales',
    prioridad: 'Rápida'
  },
  'Venta ilegal de combustible': {
    id: 30809,
    alertType: 'Venta ilegal de combustible',
    prioridad: 'Rápida'
  },
  'Lesión con arma': {
    id: 30901,
    alertType: 'Lesión con arma',
    prioridad: 'Urgente'
  },
  'Lesión con fuego': {
    id: 30901,
    alertType: 'Lesión con fuego',
    prioridad: 'Urgente'
  },
  'Lesión con objeto contundente': {
    id: 30901,
    alertType: 'Lesión con objeto contundente',
    prioridad: 'Urgente'
  },
  'Lesión con objeto punzo cortante': {
    id: 30901,
    alertType: 'Lesión con objeto punzo cortante',
    prioridad: 'Urgente'
  },
  'Violencia contra la mujer': {
    id: 30903,
    alertType: 'Violencia contra la mujer',
    prioridad: 'Rápida'
  },
  'Persona sospechosa': {
    id: 30904,
    alertType: 'Persona sospechosa',
    prioridad: 'Rápida'
  },
  'Apoyo / Por amenaza de suicidio': {
    id: 30905,
    alertType: 'Apoyo / Por amenaza de suicidio',
    prioridad: 'Urgente'
  },
  'Homicidio / Se ignora causa': {
    id: 30906,
    alertType: 'Homicidio / Se ignora causa',
    prioridad: 'Urgente'
  },
  'Homicidio con arma': {
    id: 30906,
    alertType: 'Homicidio con arma',
    prioridad: 'Urgente'
  },
  'Homicidio con fuego': {
    id: 30906,
    alertType: 'Homicidio con fuego',
    prioridad: 'Urgente'
  },
  'Homicidio con objeto contundente': {
    id: 30906,
    alertType: 'Homicidio con objeto contundente',
    prioridad: 'Urgente'
  },
  'Homicidio con objeto punzo cortante': {
    id: 30906,
    alertType: 'Homicidio con objeto punzo cortante',
    prioridad: 'Urgente'
  },
  'Homicidio por asfixia': {
    id: 30906,
    alertType: 'Homicidio por asfixia',
    prioridad: 'Urgente'
  },
  'Homicidio por inmersión en medio líquido': {
    id: 30906,
    alertType: 'Homicidio por inmersión en medio líquido',
    prioridad: 'Urgente'
  },
  'Persona lesionada por intento de suicidio': {
    id: 30908,
    alertType: 'Persona lesionada por intento de suicidio',
    prioridad: 'Urgente'
  },
  'Persona muerta por suicidio': {
    id: 30908,
    alertType: 'Persona muerta por suicidio',
    prioridad: 'Rápida'
  },
  'Agresión física': {
    id: 30909,
    alertType: 'Agresión física',
    prioridad: 'Urgente'
  },
  'Allanamiento de morada': {
    id: 31001,
    alertType: 'Allanamiento de morada',
    prioridad: 'Rápida'
  },
  'Agresión verbal': {
    id: 31002,
    alertType: 'Agresión verbal',
    prioridad: 'Normal'
  },
  'Amenaza con arma': {
    id: 31002,
    alertType: 'Amenaza con arma',
    prioridad: 'Urgente'
  },
  'Amenaza con objeto punzo cortante': {
    id: 31002,
    alertType: 'Amenaza con objeto punzo cortante',
    prioridad: 'Urgente'
  },
  'Amenaza de artefacto explosivo': {
    id: 31002,
    alertType: 'Amenaza de artefacto explosivo',
    prioridad: 'Urgente'
  },
  'Amenaza escrita': {
    id: 31002,
    alertType: 'Amenaza escrita',
    prioridad: 'Urgente'
  },
  'Amenaza por objeto contundente': {
    id: 31002,
    alertType: 'Amenaza por objeto contundente',
    prioridad: 'Urgente'
  },
  'Amenaza verbal': {
    id: 31002,
    alertType: 'Amenaza verbal',
    prioridad: 'Rápida'
  },
  'Elecciones / Acarreo de personas': {
    id: 31005,
    alertType: 'Elecciones / Acarreo de personas',
    prioridad: 'Rápida'
  },
  'Elecciones / Alteración del orden en casilla': {
    id: 31005,
    alertType: 'Elecciones / Alteración del orden en casilla',
    prioridad: 'Rápida'
  },
  'Elecciones / Atentado contra casilla electoral': {
    id: 31005,
    alertType: 'Elecciones / Atentado contra casilla electoral',
    prioridad: 'Urgente'
  },
  'Elecciones / Cierre de casilla': {
    id: 31005,
    alertType: 'Elecciones / Cierre de casilla',
    prioridad: 'Normal'
  },
  'Elecciones / Compra de votos': {
    id: 31005,
    alertType: 'Elecciones / Compra de votos',
    prioridad: 'Urgente'
  },
  'Elecciones / Manifestación con bloqueo de acceso a casilla': {
    id: 31005,
    alertType: 'Elecciones / Manifestación con bloqueo de acceso a casilla',
    prioridad: 'Rápida'
  },
  'Elecciones / Otro': {
    id: 31005,
    alertType: 'Elecciones / Otro',
    prioridad: 'Normal'
  },
  'Elecciones / Vehículo con propaganda electoral': {
    id: 31005,
    alertType: 'Elecciones / Vehículo con propaganda electoral',
    prioridad: 'Rápida'
  },
  'Fuga de reos de cárcel municipal': {
    id: 31006,
    alertType: 'Fuga de reos de cárcel municipal',
    prioridad: 'Urgente'
  },
  'Fuga de reos del CECJUDE': {
    id: 31006,
    alertType: 'Fuga de reos del CECJUDE',
    prioridad: 'Urgente'
  },
  'Fuga de reos del CECJUDE en móvil de traslado': {
    id: 31006,
    alertType: 'Fuga de reos del CECJUDE en móvil de traslado',
    prioridad: 'Urgente'
  },
  'Fuga de reos del tutelar': {
    id: 31006,
    alertType: 'Fuga de reos del tutelar',
    prioridad: 'Urgente'
  },
  'Almacenamiento de drogas': {
    id: 31007,
    alertType: 'Almacenamiento de drogas',
    prioridad: 'Urgente'
  },
  'Posesión de droga': {
    id: 31007,
    alertType: 'Posesión de droga',
    prioridad: 'Urgente'
  },
  'Tala clandestina de árboles': {
    id: 31010,
    alertType: 'Tala clandestina de árboles',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de animales': {
    id: 31012,
    alertType: 'Tráfico ilegal de animales',
    prioridad: 'Rápida'
  },
  'Venta ilegal de animales': {
    id: 31012,
    alertType: 'Venta ilegal de animales',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de drogas': {
    id: 31014,
    alertType: 'Tráfico ilegal de drogas',
    prioridad: 'Urgente'
  },
  'Venta ilegal de drogas': {
    id: 31014,
    alertType: 'Venta ilegal de drogas',
    prioridad: 'Urgente'
  },
  'Tráfico ilegal de bebidas': {
    id: 31015,
    alertType: 'Tráfico ilegal de bebidas',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de electrodomésticos': {
    id: 31015,
    alertType: 'Tráfico ilegal de electrodomésticos',
    prioridad: 'Rápida'
  },
  'Tráfico ilegal de productos alimenticios': {
    id: 31015,
    alertType: 'Tráfico ilegal de productos alimenticios',
    prioridad: 'Rápida'
  },
  'Uso de billetes falsos': {
    id: 31015,
    alertType: 'Uso de billetes falsos',
    prioridad: 'Rápida'
  },
  'Uso de cheques falsos': {
    id: 31015,
    alertType: 'Uso de cheques falsos',
    prioridad: 'Rápida'
  },
  'Venta ilegal de bebidas': {
    id: 31015,
    alertType: 'Venta ilegal de bebidas',
    prioridad: 'Rápida'
  },
  'Venta ilegal de productos': {
    id: 31015,
    alertType: 'Venta ilegal de productos',
    prioridad: 'Rápida'
  },
  'Venta ilegal de productos alimenticios': {
    id: 31015,
    alertType: 'Venta ilegal de productos alimenticios',
    prioridad: 'Rápida'
  },
  'Faltas/ Conducir en estado de ebriedad': {
    id: 31103,
    alertType: 'Faltas/ Conducir en estado de ebriedad',
    prioridad: 'Normal'
  },
  'Faltas / Ingerir bebidas prohibidas en vía pública': {
    id: 31104,
    alertType: 'Faltas / Ingerir bebidas prohibidas en vía pública',
    prioridad: 'Normal'
  },
  'Faltas / Drogarse en lugares públicos / privados': {
    id: 31105,
    alertType: 'Faltas / Drogarse en lugares públicos / privados',
    prioridad: 'Normal'
  },
  'Faltas / Grafiti': {
    id: 31108,
    alertType: 'Faltas / Grafiti',
    prioridad: 'Normal'
  },
  'Manifestación o mitin con bloqueo de vías de comunicación': {
    id: 31110,
    alertType: 'Manifestación o mitin con bloqueo de vías de comunicación',
    prioridad: 'Rápida'
  },
  'Manifestación o mitin con toma de instalaciones': {
    id: 31110,
    alertType: 'Manifestación o mitin con toma de instalaciones',
    prioridad: 'Urgente'
  },
  'Manifestación o mitin sin bloqueo de vías de comunicación': {
    id: 31110,
    alertType: 'Manifestación o mitin sin bloqueo de vías de comunicación',
    prioridad: 'Rápida'
  },
  'Apuesta ilegal / En peleas de animales': {
    id: 31111,
    alertType: 'Apuesta ilegal / En peleas de animales',
    prioridad: 'Rápida'
  },
  'Faltas / Riña': {
    id: 31112,
    alertType: 'Faltas / Riña',
    prioridad: 'Rápida'
  },
  'Apuesta ilegal / En eventos deportivos': {
    id: 31114,
    alertType: 'Apuesta ilegal / En eventos deportivos',
    prioridad: 'Rápida'
  },
  'Apuesta ilegal / En juegos de azar': {
    id: 31114,
    alertType: 'Apuesta ilegal / En juegos de azar',
    prioridad: 'Rápida'
  },
  'Faltas / A la moral': {
    id: 31114,
    alertType: 'Faltas / A la moral',
    prioridad: 'Normal'
  },
  'Faltas / Alteración del orden en propiedad privada': {
    id: 31114,
    alertType: 'Faltas / Alteración del orden en propiedad privada',
    prioridad: 'Normal'
  },
  'Faltas / Alteración del orden en vía pública': {
    id: 31114,
    alertType: 'Faltas / Alteración del orden en vía pública',
    prioridad: 'Normal'
  },
  'Faltas / Arrojar basura en la vía pública': {
    id: 31114,
    alertType: 'Faltas / Arrojar basura en la vía pública',
    prioridad: 'Normal'
  },
  'Faltas / Atisbar': {
    id: 31114,
    alertType: 'Faltas / Atisbar',
    prioridad: 'Normal'
  },
  'Faltas / Mal uso del agua': {
    id: 31114,
    alertType: 'Faltas / Mal uso del agua',
    prioridad: 'Normal'
  },
  'Faltas/ Dormido en vía pública': {
    id: 31114,
    alertType: 'Faltas/ Dormido en vía pública',
    prioridad: 'Normal'
  },
  'Servicio público / Semáforo descompuesto': {
    id: 40104,
    alertType: 'Servicio público / Semáforo descompuesto',
    prioridad: 'Normal'
  },
  'Servicio público / Alcantarilla sin tapa': {
    id: 40105,
    alertType: 'Servicio público / Alcantarilla sin tapa',
    prioridad: 'Normal'
  },
  'Servicio público / Cable caído': {
    id: 40106,
    alertType: 'Servicio público / Cable caído',
    prioridad: 'Normal'
  },
  'Servicio público / Cable eléctrico caído': {
    id: 40106,
    alertType: 'Servicio público / Cable eléctrico caído',
    prioridad: 'Rápida'
  },
  'Servicio público / Corto circuito': {
    id: 40107,
    alertType: 'Servicio público / Corto circuito',
    prioridad: 'Normal'
  },
  'Servicio público / Atención vial': {
    id: 40110,
    alertType: 'Servicio público / Atención vial',
    prioridad: 'Normal'
  },
  Desfile: {
    id: 50101,
    alertType: 'Desfile',
    prioridad: 'Normal'
  },
  'Extravío de placas de circulación de vehículo': {
    id: 50103,
    alertType: 'Extravío de placas de circulación de vehículo',
    prioridad: 'Normal'
  },
  'Usurpación de funciones': {
    id: 50106,
    alertType: 'Usurpación de funciones',
    prioridad: 'Rápida'
  },
  'Localización de feto humano': {
    id: 50201,
    alertType: 'Localización de feto humano',
    prioridad: 'Urgente'
  },
  'Localización de fosa clandestina': {
    id: 50201,
    alertType: 'Localización de fosa clandestina',
    prioridad: 'Rápida'
  },
  'Localización de osamenta humana': {
    id: 50201,
    alertType: 'Localización de osamenta humana',
    prioridad: 'Rápida'
  },
  'Localización de partes de cuerpo humano': {
    id: 50201,
    alertType: 'Localización de partes de cuerpo humano',
    prioridad: 'Rápida'
  },
  'Localización de arma': {
    id: 50202,
    alertType: 'Localización de arma',
    prioridad: 'Urgente'
  },
  'Vehículo con reporte de Robo': {
    id: 50203,
    alertType: 'Vehículo con reporte de Robo',
    prioridad: 'Rápida'
  },
  'Vehículo recuperado con reporte de Robo motocicleta': {
    id: 50203,
    alertType: 'Vehículo recuperado con reporte de Robo motocicleta',
    prioridad: 'Normal'
  },
  'Congestionamiento vial': {
    id: 50301,
    alertType: 'Congestionamiento vial',
    prioridad: 'Normal'
  },
  'Desalojo de Personas': {
    id: 50301,
    alertType: 'Desalojo de Personas',
    prioridad: 'Rápida'
  },
  Simulacro: {
    id: 50301,
    alertType: 'Simulacro',
    prioridad: 'Normal'
  },
  'Traslado forzoso de drogadicto': {
    id: 50301,
    alertType: 'Traslado forzoso de drogadicto',
    prioridad: 'Normal'
  },
  'Detención por orden de aprehensión': {
    id: 50302,
    alertType: 'Detención por orden de aprehensión',
    prioridad: 'Normal'
  },
  'Localización de Persona perdida': {
    id: 50302,
    alertType: 'Localización de Persona perdida',
    prioridad: 'Rápida'
  },
  'Apoyo / Maltrato de animal': {
    id: 50303,
    alertType: 'Apoyo / Maltrato de animal',
    prioridad: 'Rápida'
  },
  'Persona en situación de calle': {
    id: 50304,
    alertType: 'Persona en situación de calle',
    prioridad: 'Normal'
  },
  'Servicio público / Solicitud de rondines': {
    id: 50305,
    alertType: 'Servicio público / Solicitud de rondines',
    prioridad: 'Normal'
  },
  'Servicio público / Alcantarilla tapada': {
    id: 60101,
    alertType: 'Servicio público / Alcantarilla tapada',
    prioridad: 'Normal'
  },
  'Apoyo / animal muerto': {
    id: 60102,
    alertType: 'Apoyo / animal muerto',
    prioridad: 'Normal'
  },
  'Apoyo / Con remolque de vehículo': {
    id: 60103,
    alertType: 'Apoyo / Con remolque de vehículo',
    prioridad: 'Normal'
  },
  'Apoyo / Con servicio mecánico': {
    id: 60103,
    alertType: 'Apoyo / Con servicio mecánico',
    prioridad: 'Normal'
  },
  'Apoyo / Con traslado de Personas aéreo': {
    id: 60103,
    alertType: 'Apoyo / Con traslado de Personas aéreo',
    prioridad: 'Normal'
  },
  'Apoyo / Con traslado de Personas terrestre': {
    id: 60103,
    alertType: 'Apoyo / Con traslado de Personas terrestre',
    prioridad: 'Normal'
  },
  'Apoyo / Evacuación de Personas': {
    id: 60103,
    alertType: 'Apoyo / Evacuación de Personas',
    prioridad: 'Rápida'
  },
  'Apoyo / Ganado vago': {
    id: 60103,
    alertType: 'Apoyo / Ganado vago',
    prioridad: 'Normal'
  },
  'Servicio público / Fuga de agua': {
    id: 60103,
    alertType: 'Servicio público / Fuga de agua',
    prioridad: 'Normal'
  },
  'Bromas niños': {
    id: 70101,
    alertType: 'Bromas niños',
    prioridad: 'Improcedente'
  },
  'Ofensivas niños': {
    id: 70101,
    alertType: 'Ofensivas niños',
    prioridad: 'Improcedente'
  },
  'Llamada de prueba': {
    id: 70102,
    alertType: 'Llamada de prueba',
    prioridad: 'Rápida'
  },
  Colgaron: {
    id: 70104,
    alertType: 'Colgaron',
    prioridad: 'Improcedente'
  },
  Transferidas: {
    id: 70105,
    alertType: 'Transferidas',
    prioridad: 'Improcedente'
  },
  'Broma adultos': {
    id: 70106,
    alertType: 'Broma adultos',
    prioridad: 'Improcedente'
  },
  'Ofensivas adultos': {
    id: 70106,
    alertType: 'Ofensivas adultos',
    prioridad: 'Improcedente'
  },
  'Destrucción de droga': {
    id: 70108,
    alertType: 'Destrucción de droga',
    prioridad: 'Normal'
  },
  Equivocadas: {
    id: 70108,
    alertType: 'Equivocadas',
    prioridad: 'Improcedente'
  },
  'Extravío de arma de fuego': {
    id: 70108,
    alertType: 'Extravío de arma de fuego',
    prioridad: 'Urgente'
  },
  'Extravío de vehículo acuático': {
    id: 70108,
    alertType: 'Extravío de vehículo acuático',
    prioridad: 'Normal'
  },
  'Extravío de vehículo aéreo': {
    id: 70108,
    alertType: 'Extravío de vehículo aéreo',
    prioridad: 'Normal'
  },
  'Extravío de vehículo terrestre': {
    id: 70108,
    alertType: 'Extravío de vehículo terrestre',
    prioridad: 'Normal'
  },
  Información: {
    id: 70108,
    alertType: 'Información',
    prioridad: 'Improcedente'
  },
  'Localización de manta': {
    id: 70108,
    alertType: 'Localización de manta',
    prioridad: 'Rápida'
  },
  'Localización de medicamento': {
    id: 70108,
    alertType: 'Localización de medicamento',
    prioridad: 'Rápida'
  },
  'Localización de mercancía robada': {
    id: 70108,
    alertType: 'Localización de mercancía robada',
    prioridad: 'Rápida'
  },
  'Localización de uniformes militares': {
    id: 70108,
    alertType: 'Localización de uniformes militares',
    prioridad: 'Rápida'
  },
  'Localización de vehículo acuático': {
    id: 70108,
    alertType: 'Localización de vehículo acuático',
    prioridad: 'Normal'
  },
  'Localización de vehículo aéreo': {
    id: 70108,
    alertType: 'Localización de vehículo aéreo',
    prioridad: 'Normal'
  },
  'Localización de vehículo terrestre': {
    id: 70108,
    alertType: 'Localización de vehículo terrestre',
    prioridad: 'Normal'
  },
  'Notificación de traslado de vehículo con servidores públicos': {
    id: 70108,
    alertType: 'Notificación de traslado de vehículo con servidores públicos',
    prioridad: 'Normal'
  },
  Demo: {
    id: 80101,
    alertType: 'Demo',
    prioridad: 'Improcedente'
  }
}
