<template>
  <div v-if="weather" style="display:grid">
    <div class="weather">
      <img
        alt="Clima"
        class="weather-img"
        v-if="weather.weather && weather.weather.length"
        :src="`https://openweathermap.org/img/w/${weather.weather[0].icon}.png`"
      />
      <div class="temp">
        {{weather.main.temp.toFixed(0)}}°C
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      interval: null,
      weather: null
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.load()
    this.interval = setInterval(() => {
      this.load()
    }, 1000 * 60 * 30)
  },
  methods: {
    async load() {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${this.user.latitude}&lon=${this.user.longitude}&units=metric&APPID=2d78bdb2b548e2fb7cf1dd5b3ef06f31`)
      const weather = await response.json()
      this.weather = weather
    }
  }
}
</script>

<style scoped>
.weather {
  display: flex;
  justify-content: center;
}

.weather-img {
  width: 30px;
  object-fit: none;
}

.temp {
  color: white;
  font-family: 'Roboto';
  letter-spacing: 1px;
  font-weight: 900;
  align-self: center;
}
</style>
