import IncidentMovementEnd from '@/assets/map/incident_movement_end.svg';
import IncidentMovement from '@/assets/map/incident_movement.svg'
import BaseStore from './baseStore';

const ol = window.ol;

class SOSCallStore extends BaseStore {
  constructor(map, source, routeSource) {
    super(map, source);
    this.routeSource = routeSource;
    this.hoveredId = null;
    this.visibleConditionRules = {
      systemId: 'STRING',
    };
    this.cleanMarkers();
    setInterval(() => {
      this.cleanMarkers();
    }, 10000);
  }

  cleanMarkers() {
    const now = new Date().getTime();
    const keys = this.markers.keys();
    // eslint-disable-next-line no-restricted-syntax

    // for (const key of keys) {
    //   const marker = this.markers.get(key);
    //   const item = marker.get('record');
    //   const diff = now - parseInt(item?.lastPosition?.additionalData?.call_start_time || '0');
    //   if (diff >= 20 * 60 * 1000) {
    //     this.markers.delete(key);
    //     this.olSource.removeFeature(marker);
    //     if (this.visibleItems.indexOf(item) > -1) {
    //       const index = this.visibleItems.indexOf(item);
    //       this.visibleItems.splice(index, 1);
    //     }
    //   }
    // }
  }

  _createMarker(item) {
    const location = item.lastPosition;
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(
        window.ol.proj.fromLonLat([location.longitude, location.latitude])
      ),
      name: item.userData?.phoneNumber || 'Desconocido',

    });
    marker.setId('reportant__' + item._id);
    marker.set('record', item);
    this.markers.set(item._id, marker);

    this._updateMarker(item);
    return marker;
  }

  createRoute(item) {
    this.deleteRoute();

    console.log('createRoute', item)

    if (!item.positionsHistory || item.positionsHistory.length < 2) {
      return;
    }

    const points = item.positionsHistory.map((position) => {
      return {
        ...item,
        position,
        point: ol.proj.transform([position.longitude, position.latitude], 'EPSG:4326', 'EPSG:3857')

      }
    })

    const featureLine = new ol.Feature({
      geometry: new ol.geom.LineString(points.map((p) => p.point))
    })
    featureLine.setId('route_' + item._id)

    featureLine.setStyle(new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: '#14a3c7',
        width: 5
      }),
      zIndex: 10
    }))
    console.log(featureLine)

    const point = points[0]
    const circleFeature = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        point.position.longitude, point.position.latitude
      ])),
      name: point.position.timestamp,
      population: 4000,
      rainfall: 500
    });
    circleFeature.setId(`${item._id}_`)
    circleFeature.set('record', item)
    circleFeature.setStyle(new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: IncidentMovement,
        // scale: options.scale,
      }),
      zIndex: 20
    }))

    // const circles = points.map((point, i) => {
    //   const circleFeature = new ol.Feature({
    //     geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
    //       point.position.longitude, point.position.latitude
    //     ])),
    //     name: point.position.timestamp,
    //     population: 4000,
    //     rainfall: 500
    //   });
    //   circleFeature.setId(`${item._id}_${i}`)
    //   circleFeature.set('record', item)
    //   const icond = IncidentMovement
    //   if (i === item.positionsHistory.length - 1) {
    //     icond = IncidentMovementEnd
    //   }
    //   circleFeature.setStyle(new ol.style.Style({
    //     image: new ol.style.Icon({
    //       anchor: [0.5, 0.9],
    //       anchorXUnits: 'fraction',
    //       anchorYUnits: 'fraction',
    //       src: IncidentMovement,
    //       // scale: options.scale,
    //     }),
    //     zIndex: 20
    //   }))
    //   return circleFeature
    // })

    this.routeSource.addFeature(featureLine);
    this.routeSource.addFeature(circleFeature)
  }

  deleteRoute() {
    console.log('deleteRoute')
    this.routeSource.clear()
  }

  _updateMarker(item, _visible = false) {
    const tmpMarker = this.markers.get(item._id);
    tmpMarker.setStyle(this._getStyle(item));
    const record = tmpMarker.get('record');
    const location = item.lastPosition
    const oldLocation = record.lastPosition;
    if (
      location.latitude !== oldLocation.latitude
      || location.longitude !== oldLocation.longitude
    ) {
      tmpMarker
        .getGeometry()
        .setCoordinates(window.ol.proj.fromLonLat([location.longitude, location.latitude]));
    }
    const visible = this._isVisible(item)
    console.log('visible', visible)
    Object.assign(record, item);
    this.notify(item);
    this._updateVisbility(tmpMarker, visible);
    if (_visible) {
      this.createRoute(item);
    }
  }

  deleteMarker(id) {
    this.deleteRoute();
    const marker = this.markers.get(id);
    this.markers.delete(id);
    this.visibleMarkers.delete(id)
    if (!marker) return;
    this.olSource.removeFeature(marker)
    const item = marker.get('record')
    if (this.visibleItems.indexOf(item) > -1) {
      const index = this.visibleItems.indexOf(item);
      this.visibleItems.splice(index, 1)
    }
  }

  updateMarker(item, visible = false) {
    item.id = item._id;
    if (!this.markers.get(item.id)) {
      return this._createMarker(item);
    }
    this._updateMarker(item, visible);
    return null;
  }

  _getIcon(image, label, options = {}) {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: image,
        scale: options.scale,
        rotation: options.rotation
      }),
      text: new ol.style.Text({
        text: label,
        scale: 1,
        fill: new ol.style.Fill({
          color: '#fff'
        }),
        offsetY: options.offsetY || -35,
        offsetX: options.offsetX || -10,
        stroke: new ol.style.Stroke({
          color: '0',
          width: 3
        })
      })
    })
  }

  _getStyle(item) {
    return this._getIcon(IncidentMovementEnd, item.userData?.phoneNumber || 'Desconocido', {
      offsetY: 0,
      offsetX: 0,
    });
  }

  _getStyleSelected(item) {
    return this._getIcon(IncidentMovementEnd, item.userData?.phoneNumber || 'Desconocido', {
      offsetY: 0,
      offsetX: 0,
    });
  }

  updateMarkersStyle() {
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')));
    });
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId);
      marker.setStyle(this._getStyle(marker.get('record')));
      this.hoveredId = null;
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id);
      marker.setStyle(this._getStyleSelected(marker.get('record')));
      this.hoveredId = id;
    }
  }
}

export default SOSCallStore;
